import { Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth/auth.guard';

export const routes: Routes = [
  //#region rotas atendente
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth-area/auth.routes').then((c) => c.AUTH_ROUTES),
  },
  {
    path: '',
    // canActivate: [authGuard],
    loadChildren: () =>
      import('./pages/pages.routes').then((c) => c.PAGES_ROUTER),
  },
  //#endregion
];
