import { environment } from './environment';

export const regresUrl: string = 'https://reqres.in/api/';
export const mevoURL: string = 'https://emr-homolog.nexodata.com.br/api/';
export const endpoints = {
  authentication: {
    login: 'account/authenticate',
    getAccount: 'account/getAccount',
    recoveryPassword: 'account/recoveryPassword',
    resetPassword: 'account/resetPassword',
    getPendingAccount: 'account/getPendingAccount',
    completeAccount: 'account/completeAccount',
    addAccount: 'account/addAccount',
  },
  openAi: {
    uploadFile: 'https://api.openai.com/v1/files',
  },
  common: {
    viaCep: 'https://viacep.com.br/ws/',
  },
  location: {
    states: {
      getAll: `${environment.baseApiUrl}Location/GetAllStates`,
    },
    cities: {
      getAll: `${environment.baseApiUrl}Location/GetAllCities`,
    },
  },
  patients: {
    add: `${environment.baseApiUrl}Patient/AddPatient`,
    addHistory: `${environment.baseApiUrl}Patient/AddPatientMedicalHistory`,
    getAll: `${environment.baseApiUrl}Patient/GetAllPatients`,
    getById: `${environment.baseApiUrl}Patient/GetPatient`,
    update: `${environment.baseApiUrl}Patient/UpdatePatient`,
    delete: `${environment.baseApiUrl}Patient/DeletePatient`,
  },
  organization: {
    add: `${environment.baseApiUrl}organization/getAllOrganizations`,
    getAll: `${environment.baseApiUrl}organization/getAllOrganizations`,
    getOrganization: `${environment.baseApiUrl}organization/getOrganization`,
    addOrganization: `${environment.baseApiUrl}organization/AddOrganization`,
    updateOrganization: `${environment.baseApiUrl}organization/UpdateOrganization`,
    delete: `${environment.baseApiUrl}organization/DeletePatient`,
    createAdmin: `${environment.baseApiUrl}organization/DeletePatient`,
    updateAdmin: `${environment.baseApiUrl}organization/DeletePatient`,
    deleteAdmin: `${environment.baseApiUrl}organization/DeletePatient`,
    getBusinessModelMatrix: `${environment.baseApiUrl}organization/GetBusinessModelMatrix`,
  },
  administrator: {
    getAllAdministrators: `${environment.baseApiUrl}administrator/getAllAdministrators`,
    addOrganizationAdminShort: `${environment.baseApiUrl}administrator/addOrganizationAdminShort`,
    inactiveOrActiveOrganizationAdminShort: `${environment.baseApiUrl}administrator/inactiveOrActiveOrganizationAdminShort`,
  },
  professional: {
    add: `${environment.baseApiUrl}Professional/AddProfessional`,
    getAll: `${environment.baseApiUrl}Professional/GetAllProfessionals`,
    getAllBoards: `${environment.baseApiUrl}Professional/GetAllRegulatoryBoards`,
    getById: `${environment.baseApiUrl}Professional/GetProfessional`,
    update: `${environment.baseApiUrl}Professional/UpdateProfessional`,
    delete: `${environment.baseApiUrl}Professional/DeleteProfessional`,
    specialties: {
      getAllSpecialties: `${environment.baseApiUrl}Professional/GetAllSpecialties`,
    },
  },
  scheduler: {
    add: `${environment.baseApiUrl}Scheduler/AddScheduler`,
    getAll: `${environment.baseApiUrl}Scheduler/GetAllSchedulers`,
    getById: `${environment.baseApiUrl}Scheduler/GetScheduler`,
    update: `${environment.baseApiUrl}Scheduler/UpdateScheduler`,
    delete: `${environment.baseApiUrl}Scheduler/DeleteScheduler`,
  },
  mevo: {
    prescription: `${mevoURL}prescricao/`,
  },
  schedule: {
    addProfessionalSchedule: `${environment.baseApiUrl}Schedule/AddProfessionalSchedule`,
    addAppointment: `${environment.baseApiUrl}Schedule/AddAppointment`,
    addPatientNote: `${environment.baseApiUrl}Schedule/AddPatientNote`,
    addPatientPrescription: `${environment.baseApiUrl}Schedule/AddPatientPrescription`,
    getProfessionalSchedule: `${environment.baseApiUrl}Schedule/GetProfessionalSchedule`,
    getAppointmentDetails: `${environment.baseApiUrl}Schedule/GetAppointmentDetails`,
    getAvailableScheduleProfessional: `${environment.baseApiUrl}Schedule/GetAvailableScheduleProfessional`,
    getUpcomingPatientAppointments: `${environment.baseApiUrl}Schedule/GetUpcomingPatientAppointments`,
    getUpcomingProfessionalAppointments: `${environment.baseApiUrl}Schedule/GetUpcomingProfessionalAppointments`,
    getUpcomingAppointments: `${environment.baseApiUrl}Schedule/GetUpcomingAppointments`,
    getAllPatientNotes: `${environment.baseApiUrl}Schedule/GetAllPatientNotes`,
    updateProfessionalSchedule: `${environment.baseApiUrl}Schedule/UpdateProfessionalSchedule`,
    deleteAppointment: `${environment.baseApiUrl}Schedule/DeleteAppointment`,
  },
  chime: {
    createMeeting: `${environment.baseApiUrl}Meeting/AddMeeting`,
    createAttendee: `${environment.baseApiUrl}Meeting/AddAttendee`,
    deleteMeeting: `${environment.baseApiUrl}Meeting/DeleteMeeting`,
    deleteAttendee: `${environment.baseApiUrl}Meeting/DeleteAttendee`,
    getMeeting: `${environment.baseApiUrl}Meeting/GetMeeting`,
    getAttendee: `${environment.baseApiUrl}Meeting/GetAttendee`,
    listAttendees: `${environment.baseApiUrl}Meeting/GetAllAttendees`,
    addTranscription: `${environment.baseApiUrl}Meeting/AddTranscription`,
    deleteTranscription: `${environment.baseApiUrl}Meeting/DeleteTranscription`,
  },
  notification: {
    notifications: `${environment.baseUrl}notifications`,
  },
  storage: {
    upload: `${environment.baseApiUrl}storage/upload`,
    delete: `${environment.baseApiUrl}storage/delete`,
  },
};
